// gatsby-browser.js
import './src/styles/webfonts.css';
import './src/styles/plyr.css';
import './src/styles/manual.css';
import './src/styles/global.css';

const onRouteUpdate = () => {
  window.scrollTo(0, 0);
};

export { onRouteUpdate };
