module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","name":"Buravida","short_name":"Buravida","description":"Wir sind Kreative, die massgeschneiderte Lösungen bieten, welche Design und Funktionalität perfekt vereinen – für unvergessliche Momente und beeindruckende Räume!","lang":"de","display":"standalone","start_url":"/","background_color":"#000","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"60875bcfb5f5563822b3b60f3fd93fc8"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
